import { useState } from "react"
import Datepicker from "./DatePicker"
import { setMaxIdleHTTPParsers } from "http"
import { SaveCountdown } from "./api/api"
import { ICountdown } from "./types"

interface CountdownAddProps {
  reloadCountdowns: React.Dispatch<React.SetStateAction<Date>>
}

const CountdownAdd = (props: CountdownAddProps) => {

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [name, setName] = useState<string>()
  const [date, setDate] = useState<Date>(new Date())
  const [dateField, setDateField] = useState<string>()
  const [timeField, setTimeField] = useState<string>()


  const setTest = (e: any) => {

    console.log(e)
    console.log(typeof e)

  }

  const onChangeSetName = (e: string) => {
    setName(e)
  }

  const onChangeSetDate = (e: string) => {
    setDateField(e)
    console.log(`called onChangeSetDate with ${e}`)
    const d = date
    console.log(`initial date is ${d}`)
    const dateParts = e.split("-")
    console.log(`dateparts is ${dateParts}`)
    d?.setFullYear(Number(dateParts[0]))
    d?.setMonth(Number(dateParts[1]))
    d?.setDate(Number(dateParts[2]))
    console.log(`resulting date is ${d}`)
    setDate(d)
  }

  const onChangeSetTime = (e: string) => {
    setTimeField(e)
    console.log(`called onChangeSetTime with ${e}`)
    const timeParts = e.split(":")
    const d = date
    d.setHours(Number(timeParts[0]))
    d.setMinutes(Number(timeParts[1]))
  }

  const clickSaveCountdown = async () => {
    
    // validate
    if (!name) {
      return
    }
    // TODO: check the date for... stuff
    if (date) {

    }

    const countdown: ICountdown = {
      name: name,
      user: sessionStorage.getItem("username") || "",
      date: date
    }

    const response = await SaveCountdown(countdown)
    if (!response.success) {
      if (response.errorMessage) {
          setErrorMessage(response.errorMessage)
      } else {
          setErrorMessage("There was an error submitting your countdown")
      }
      return
    }
    setName('')
    setDateField('')
    setTimeField('')
    setDate(new Date())
    props.reloadCountdowns(new Date())
  }


  return <div className="max-w-sm mx-auto">
    <div className="mb-5">
      <label htmlFor ="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
      <input type="text" 
        id="name" 
        value={name}
        onChange={(e) => onChangeSetName(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        placeholder="name@flowbite.com" 
        required />
    </div>
    <div className="mb-5">
      <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
      <input 
        id="date"
        value={dateField}
        type="date"
        onChange={(e) => onChangeSetDate(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        required />
    </div>
    <div className="mb-5">
      <label htmlFor="time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Time</label>
      <input 
        id="time"
        value={timeField}
        type="time"
        onChange={(e) => onChangeSetTime(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        required />
    </div>

    <button 
      id="save"
      type="button"
      onClick={() => clickSaveCountdown()} 
      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
  </div>
    
}

export default CountdownAdd