import { useCountdown } from "./useCountdown"

interface CountdownTimerProps {
    title: string,
    untilDate: Date
}

const CountdownTimer = (props: CountdownTimerProps) => {

    const [days, hours, minutes, seconds] = useCountdown(props.untilDate)

    return <section>
        <div className="container mx-auto py-4">
            <div className="py-2">
                <h2 className="text-center text-xl">{props.title}</h2>
            </div>
            <div className="flex items-center justify-center w-full gap-1.5 count-down-main">
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element days font-manrope font-semibold text-2xl text-white text-center">
                            {days}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">days</p>
                    </div>
                </div>
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element hours font-manrope font-semibold text-2xl text-white text-center">
                            {hours}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">Hour</p>
                    </div>
                </div>
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element minutes font-manrope font-semibold text-2xl text-white text-center">
                            {minutes}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">Minutes</p>
                    </div>
                </div>
                <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
                <div className="timer">
                    <div className="rounded-xl bg-gradient-to-b from-indigo-600 to-purple-600 py-3 min-w-[96px] flex items-center justify-center flex-col gap-1 aspect-square px-3">
                        <h3 className="countdown-element seconds font-manrope font-semibold text-2xl text-white text-center">
                            {seconds}
                        </h3>
                        <p className="text-lg font-manrope font-normal text-white mt-1 text-center w-full">Seconds</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
}

export default CountdownTimer;